import { ReactNode, useCallback, useContext, useState } from "react";

import Grid from "@mui/material/Grid";
import { FormCategory } from "@simplyk/common";
import { Trans } from "react-i18next";

import { AmplitudeEvents } from "../../../constants/amplitude";
import { ShareFormModalContext } from "../../../contexts/ShareFormModalContext";
import { useTranslate } from "../../../hooks/useTranslate";
import { ZeffyConfetti } from "../../Animations/ZeffyConfetti/ZeffyConfetti";
import { DialogCloseButton } from "../../DialogCloseButton/DialogCloseButton";
import Image from "../../Image/Image";
import { ShareThisButton } from "../../ShareThis/types";
import { TextFieldWithCopy } from "../../TextFieldWithCopy/TextFieldWithCopy";

import { useStyles } from "./useStyles";

import { ShareThis } from "@/components/ShareThis/ShareThis";
import { Infobox } from "@/design-system/Infobox";
import { Typography } from "@/design-system/Typography";

export const ShareFormModalContentTitle = ({ title }: { title?: ReactNode }) => {
  const { t } = useTranslate();
  const classes = useStyles();
  const { formLink, isFormCreation, onClose, formCategory } = useContext(ShareFormModalContext);
  const [linkCopied, setLinkCopied] = useState(false);
  const onCopy = useCallback(() => {
    setLinkCopied(true);
  }, []);

  return (
    <>
      {isFormCreation && <ZeffyConfetti canLoadData={isFormCreation} />}
      <Grid container>
        <DialogCloseButton data-test="share-form-modal-close-button" onClose={onClose} vibe="text-reverse-intense" />
        <Grid item xs={12} sx={{ paddingBottom: { xs: 2, md: 0 }, textAlign: "center" }}>
          <Grid container justifyContent="center">
            <Grid item xs={2} />
            <Grid item xs={8} marginBottom={1}>
              <Typography vibe="text-reverse-intense" variant="h5">
                {title ? (
                  title
                ) : (
                  <Trans
                    i18nKey={
                      isFormCreation ? "dashboard:shareFormModal.title.formIsReady" : "dashboard:shareFormModal.title"
                    }
                    components={{ br: <br /> }}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={2} />

            <Grid item xs={12} marginLeft={3} marginRight={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.linkInputLabel}>
                    {t("dashboard", "shareFormModal.linkInputLabel")}
                  </Typography>
                </Grid>
                {formLink && (
                  <Grid item xs={12}>
                    <TextFieldWithCopy
                      value={formLink}
                      button
                      onCopy={onCopy}
                      size="medium"
                      buttonCopyProps={{ size: "medium" }}
                      data-test="share-form-modal-link"
                      copyAmplitudeEvent={AmplitudeEvents.FormSharingClicked}
                      copyAmplitudePayload={{ social_platform: "Copy Link", form_type: formCategory }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {linkCopied && (
              <>
                <Grid item xs={12} marginLeft={3} marginRight={3}>
                  <Infobox
                    hideIcon
                    vibe="brand"
                    type="alert"
                    pointer="top-left"
                    size="medium"
                    title={t("dashboard", "shareFormModal.infoBox.title")}
                    className={classes.copyInfoBox}
                    slots={{
                      right: (
                        <Image
                          width={98}
                          height={undefined}
                          alt="share-icons"
                          src="/images/shareFormModal/share-icons.svg"
                        />
                      ),
                    }}
                  >
                    {t("dashboard", "shareFormModal.infoBox.content")}
                  </Infobox>
                </Grid>
                <Grid item xs={1} />
              </>
            )}
            <Grid item paddingTop={1}>
              <ShareThis
                subTitle=""
                buttons={[ShareThisButton.Facebook, ShareThisButton.Messenger, ShareThisButton.Email]}
                url={formLink}
                formCategory={formCategory ? FormCategory[formCategory] : null}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
