import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    dialogTitle: {
      backgroundColor: theme.palette.surface.brand.intense,
      position: "relative",
    },
    dialogTitleContent: {
      paddingBottom: theme.spacing(2),
      textAlign: "center",
    },
    dialogContent: {},
    container: {
      [`& $dialogContent`]: {
        padding: theme.spacing(3, 3, 0, 3),
      },
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3, 2, 0, 2),
      },
    },
    dialogActions: {
      padding: theme.spacing(3, 3, 3, 3),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(2, 3, 3, 3),
      },
    },
    textAlignCenter: {
      textAlign: "center",
    },
  }),
  { index: 10 }
);
