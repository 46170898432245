import { useContext } from "react";

import Grid from "@mui/material/Grid";

import { ShareFormModalContext } from "../../../contexts/ShareFormModalContext";
import { useTranslate } from "../../../hooks/useTranslate";
import { Typography } from "../../design-system/Typography";
import { DialogCloseButton } from "../../DialogCloseButton/DialogCloseButton";

import { useStyles } from "./useStyles";

export const ShareFormModalQrCodeContentTitle = () => {
  const { t } = useTranslate();
  const classes = useStyles();
  const { onClose } = useContext(ShareFormModalContext);
  return (
    <Grid container>
      <DialogCloseButton data-test="share-form-modal-close-button" onClose={onClose} vibe="text-reverse-intense" />
      <Grid item xs={12} className={classes.dialogTitleContent}>
        <Grid container justifyContent="center">
          <Grid item xs={2} />
          <Grid item xs={8} marginBottom={1}>
            <Typography vibe="text-reverse-intense" variant="h5" align="center">
              {t("dashboard", "shareFormModal.qrCode.title")}
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Grid>
    </Grid>
  );
};
