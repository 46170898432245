import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  () => ({
    qrCode: {
      textAlign: "center",
    },
  }),
  { index: 10 }
);
