import { useContext } from "react";

import Grid from "@mui/material/Grid";

import { ShareFormModalContext } from "../../../contexts/ShareFormModalContext";
import { useTranslate } from "../../../hooks/useTranslate";
import { Infobox } from "../../design-system/Infobox";
import { FormQrCode } from "../../FormQrCode/FormQrCode";

export const ShareFormModalQrCodeContentContent = () => {
  const { t } = useTranslate();
  const { formLink } = useContext(ShareFormModalContext);
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Infobox
          vibe="brand"
          type="alert"
          size="medium"
          pointer="bottom"
          title={t("dashboard", "shareFormModal.qrCode.infoBox.title")}
          hideIcon
        >
          {t("dashboard", "shareFormModal.qrCode.infoBox.content")}
        </Infobox>
      </Grid>
      {formLink && (
        <Grid item xs={12}>
          <FormQrCode formLink={formLink} />
        </Grid>
      )}
    </Grid>
  );
};
