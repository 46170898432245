import { useCallback, useContext } from "react";

import Grid from "@mui/material/Grid";

import { EmbedChoice, ShareFormModalContext } from "../../../contexts/ShareFormModalContext";
import { useTranslate } from "../../../hooks/useTranslate";
import { Button } from "../../design-system/Button";

import { ChevronLeft, ChevronRight } from "@/icons/outlined";

interface ShareFormModalEmbedContentActionsProps {
  tab: EmbedChoice;
  isChosen: boolean;
  onBack: () => void;
  onChoose: () => void;
}
const lexusEasterEggCounterByTab = {
  [EmbedChoice.Form]: 2,
  [EmbedChoice.ButtonPopup]: 3,
  [EmbedChoice.Thermometer]: 4,
  [EmbedChoice.Leaderboard]: 5,
  [EmbedChoice.LinkedButton]: 6,
};

export const ShareFormModalEmbedContentActions = ({
  tab,
  onChoose,
  onBack,
  isChosen,
}: ShareFormModalEmbedContentActionsProps) => {
  const { t } = useTranslate();
  const { updateLexusEasterEggCounter } = useContext(ShareFormModalContext);
  const handleChoose = useCallback(() => {
    updateLexusEasterEggCounter(lexusEasterEggCounterByTab[tab]);
    onChoose();
  }, [onChoose, tab, updateLexusEasterEggCounter]);
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Button vibe="neutral" variant="outlined" size="large" startIcon={<ChevronLeft />} onClick={onBack}>
          {t("common", "back")}
        </Button>
      </Grid>
      {!isChosen && (
        <Grid item>
          <Button vibe="brand" variant="filled" size="large" endIcon={<ChevronRight />} onClick={handleChoose}>
            {t("dashboard", "common.choose")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
