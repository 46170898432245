import { useContext } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { FormType } from "@simplyk/common";
import classnames from "classnames";

import { ModalContent, ShareFormModalContext } from "../../contexts/ShareFormModalContext";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { ShareFormModalProvider } from "../../providers/ShareFormModalProvider";
import { Drawer } from "../design-system/Drawer";
import { ZeffyLoader } from "../design-system/ZeffyLoader/ZeffyLoader";

import { ShareFormModalContentActions } from "./ShareFormModalContent/ShareFormModalContentActions";
import { ShareFormModalContentContent } from "./ShareFormModalContent/ShareFormModalContentContent";
import { ShareFormModalContentTitle } from "./ShareFormModalContent/ShareFormModalContentTitle";
import { ShareFormModalEmbedContentActions } from "./ShareFormModalEmbedContent/ShareFormModalEmbedContentActions";
import { ShareFormModalEmbedContentContent } from "./ShareFormModalEmbedContent/ShareFormModalEmbedContentContent";
import { ShareFormModalEmbedContentTitle } from "./ShareFormModalEmbedContent/ShareFormModalEmbedContentTitle";
import { ShareFormModalQrCodeContentActions } from "./ShareFormModalQrCodeContent/ShareFormModalQrCodeContentActions";
import { ShareFormModalQrCodeContentContent } from "./ShareFormModalQrCodeContent/ShareFormModalQrCodeContentContent";
import { ShareFormModalQrCodeContentTitle } from "./ShareFormModalQrCodeContent/ShareFormModalQrCodeContentTitle";
import { useStyles } from "./useStyles";

import { InvitationEmailModalContentContent } from "@/components/ShareFormModal/InvitationModalContent/InvitationEmailModalContentContent";
import { Trans } from "@/hooks/useTranslate";

interface ShareFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  formId: string;
  formType: FormType;
  isFormCreation?: boolean;
  amplitudePayload?: Record<string, unknown>;
  defaultModalContent?: ModalContent;
  mobileAdditionalStyle?: React.CSSProperties;
  showViewMyFormButton?: boolean;
}

export const ShareFormModal = (props: ShareFormModalProps) => {
  const { isOpen, formId, formType, amplitudePayload, onClose } = props;
  return (
    <ShareFormModalProvider
      formId={formId}
      formType={formType}
      onClose={onClose}
      amplitudePayload={amplitudePayload}
      isOpen={isOpen}
      defaultModalContent={props.defaultModalContent}
    >
      <ShareFormModalInner {...props} />
    </ShareFormModalProvider>
  );
};

const ShareFormModalInner = ({
  isOpen,
  defaultModalContent = ModalContent.ShareForm,
  mobileAdditionalStyle,
  showViewMyFormButton = true,
}: ShareFormModalProps) => {
  const { isSmallScreen } = useMediaQuery();
  const classes = useStyles();

  const {
    isChosen,
    tab,
    content,
    onShowEmbedContent,
    onShowQrCodeContent,
    handleTabChange,
    onInvitationEmailClick,
    onOtherOptionsClick,
    setContent,
    onShowShareFormContent,
    onChoose,
    handleBack,
    handleClose,
    isLoaded,
  } = useContext(ShareFormModalContext);

  const titleComponent: Record<ModalContent, React.ReactNode> = {
    [ModalContent.ShareForm]: <ShareFormModalContentTitle />,
    [ModalContent.QrCode]: <ShareFormModalQrCodeContentTitle />,
    [ModalContent.Embed]: <ShareFormModalEmbedContentTitle isChosen={isChosen} tab={tab} />,
    [ModalContent.InvitationEmail]: (
      <ShareFormModalContentTitle
        title={<Trans space="dashboard" i18nKey="shareFormModal.title.formIsReady" components={{ br: <br /> }} />}
      />
    ),
  };

  const contentComponent: Record<ModalContent, React.ReactNode> = {
    [ModalContent.ShareForm]: (
      <ShareFormModalContentContent onShowEmbedContent={onShowEmbedContent} onShowQrCodeContent={onShowQrCodeContent} />
    ),
    [ModalContent.QrCode]: <ShareFormModalQrCodeContentContent />,
    [ModalContent.Embed]: (
      <ShareFormModalEmbedContentContent isChosen={isChosen} tab={tab} handleTabChange={handleTabChange} />
    ),
    [ModalContent.InvitationEmail]: (
      <InvitationEmailModalContentContent
        onInvitationEmailClick={onInvitationEmailClick}
        onOtherOptionsClick={onOtherOptionsClick}
      />
    ),
  };

  const actionsComponent: Record<ModalContent, React.ReactNode> = {
    [ModalContent.ShareForm]: (
      <ShareFormModalContentActions
        displayBackButton={defaultModalContent === ModalContent.InvitationEmail}
        onBackButtonClick={() => setContent(ModalContent.InvitationEmail)}
      />
    ),
    [ModalContent.QrCode]: <ShareFormModalQrCodeContentActions onBack={onShowShareFormContent} />,
    [ModalContent.Embed]: (
      <ShareFormModalEmbedContentActions
        isChosen={isChosen}
        onChoose={onChoose}
        tab={tab}
        onBack={isChosen ? handleBack : onShowShareFormContent}
      />
    ),
    [ModalContent.InvitationEmail]: null,
  };

  const displayedTitleComponent = isLoaded ? titleComponent[content] : null;
  const displayedActionsComponent = isLoaded ? actionsComponent[content] : <ZeffyLoader />;
  const displayedContentComponent = isLoaded ? contentComponent[content] : null;

  return (
    <>
      {isSmallScreen && (
        <Drawer
          onClose={handleClose}
          open={isOpen}
          anchor="bottom"
          height="90vh"
          data-test="share-form-modal"
          className={classes.container}
          slots={{
            header: (
              <Grid container item className={classes.dialogTitle}>
                {displayedTitleComponent}
              </Grid>
            ),
            footer: showViewMyFormButton ? (
              <Grid container item className={classes.dialogActions}>
                {displayedActionsComponent}
              </Grid>
            ) : undefined,
          }}
          style={mobileAdditionalStyle}
          hasStickyHeader
        >
          <Grid container item className={classnames({ [classes.dialogContent]: !isSmallScreen })}>
            {displayedContentComponent}
          </Grid>
        </Drawer>
      )}
      {!isSmallScreen && (
        <Dialog
          open={isOpen}
          maxWidth="sm"
          onClose={handleClose}
          data-test="share-form-modal"
          className={classes.container}
        >
          <DialogTitle className={classes.dialogTitle}>{displayedTitleComponent}</DialogTitle>
          <DialogContent className={classes.dialogContent} sx={{ overflowY: "unset" }}>
            {displayedContentComponent}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>{displayedActionsComponent}</DialogActions>
        </Dialog>
      )}
    </>
  );
};
