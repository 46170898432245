import { lighten, Theme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";

export const BUTTON_SIZE = 32;

export const getFlyingButtonStyles = (theme: Theme, buttonSize = BUTTON_SIZE, circleColor?: string): CSSProperties => ({
  height: buttonSize,
  width: buttonSize,
  backgroundColor: circleColor ? circleColor : lighten(theme.palette.primary.main, 0.9),
  borderRadius: "50%",
  cursor: "pointer",
  transition: "opacity 0.2s ease-in, margin-top 0.2s ease-in",
  "&:hover": {
    opacity: 0.8,
    marginTop: -5,
  },
});
