import Grid from "@mui/material/Grid";

import { useTranslate } from "../../../hooks/useTranslate";
import { Button } from "../../design-system/Button";

import { ChevronLeft } from "@/icons/outlined";

interface ShareFormModalQrCodeContentActionsProps {
  onBack: () => void;
}

export const ShareFormModalQrCodeContentActions = ({ onBack }: ShareFormModalQrCodeContentActionsProps) => {
  const { t } = useTranslate();
  return (
    <Grid container>
      <Grid item>
        <Button vibe="neutral" variant="outlined" size="large" startIcon={<ChevronLeft />} onClick={onBack}>
          {t("common", "back")}
        </Button>
      </Grid>
    </Grid>
  );
};
