import { createContext, Dispatch, SetStateAction } from "react";

import { DonationFormCategory, TicketingFormCategory, Nullable } from "@simplyk/common";

export enum EmbedChoice {
  Form = "Form",
  ButtonPopup = "ButtonPopup",
  Thermometer = "Thermometer",
  Leaderboard = "Leaderboard",
  LinkedButton = "LinkedButton",
}

export enum ModalContent {
  ShareForm = "ShareForm",
  QrCode = "QrCode",
  Embed = "Embed",
  InvitationEmail = "InvitationEmail",
}
interface ShareFormModalContextProps {
  formCategory: TicketingFormCategory | DonationFormCategory | null;
  isLoaded: boolean;
  isFormCreation: boolean;
  formLink?: Nullable<string>;
  formIframeHtml: Nullable<string>;
  buttonZeffyProperty: Nullable<string>;
  thermometerIframeHtml: Nullable<string>;
  leaderboardIframeHtml: Nullable<string>;
  updateLexusEasterEggCounter: (value: number) => void;
  embedChoices: EmbedChoice[];
  onClose: () => void;
  demotivateEmbeds: boolean;
  isChosen: boolean;
  tab: EmbedChoice;
  onShowEmbedContent: () => void;
  onShowQrCodeContent: () => void;
  handleTabChange: (value: EmbedChoice) => void;
  onInvitationEmailClick: () => void;
  onOtherOptionsClick: () => void;
  setContent: Dispatch<SetStateAction<ModalContent>>;
  onShowShareFormContent: () => void;
  onChoose: () => void;
  handleBack: () => void;
  content: ModalContent;
  handleClose: () => void;
}

export const ShareFormModalContext = createContext<ShareFormModalContextProps>({
  formCategory: null,
  isLoaded: false,
  isFormCreation: false,
  formLink: null,
  formIframeHtml: null,
  buttonZeffyProperty: null,
  thermometerIframeHtml: null,
  leaderboardIframeHtml: null,
  updateLexusEasterEggCounter: () => {},
  embedChoices: [],
  onClose: () => {},
  demotivateEmbeds: false,
  isChosen: false,
  tab: EmbedChoice.Form,
  content: ModalContent.ShareForm,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onShowEmbedContent: () => {},
  onShowQrCodeContent: () => {},
  handleTabChange: () => {},
  onInvitationEmailClick: () => {},
  onOtherOptionsClick: () => {},
  setContent: () => {},
  onShowShareFormContent: () => {},
  onChoose: () => {},
  handleBack: () => {},
  handleClose: () => {},
});
