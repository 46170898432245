import { useCallback, useContext } from "react";

import Grid from "@mui/material/Grid";

import { AmplitudeEvents } from "../../../constants/amplitude";
import { ShareFormModalContext } from "../../../contexts/ShareFormModalContext";
import { useAmplitude } from "../../../hooks/amplitude/useAmplitude";
import { OpenForm } from "../../OpenForm/OpenForm";

import { Button } from "@/design-system/Button/Button";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useTranslate } from "@/hooks/useTranslate";
import { ChevronLeft } from "@/icons/outlined";

interface ShareFormModalContentActionsProps {
  onBackButtonClick: () => void;
  displayBackButton?: boolean;
}

export const ShareFormModalContentActions = ({
  onBackButtonClick,
  displayBackButton = false,
}: ShareFormModalContentActionsProps) => {
  const { logAmplitudeEvent } = useAmplitude();
  const { formLink } = useContext(ShareFormModalContext);
  const onOpenFormClick = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.ShareFormModalClickSeeForm);
  }, [logAmplitudeEvent]);
  const { t } = useTranslate();
  const { isSmallScreen } = useMediaQuery();

  return (
    <Grid container justifyContent={displayBackButton ? "space-between" : "flex-end"}>
      {displayBackButton && (
        <Grid item marginRight={1}>
          <Button vibe="neutral" variant="outlined" onClick={onBackButtonClick} startIcon={<ChevronLeft />}>
            {t("dashboard", "common.back")}
          </Button>
        </Grid>
      )}
      {isSmallScreen && (
        <Grid item>
          <OpenForm link={formLink} vibe="brand" variant="filled" onClick={onOpenFormClick} />
        </Grid>
      )}
    </Grid>
  );
};
