import * as React from "react";

import { Box } from "@mui/material";
import { styled, Theme, ThemeProvider } from "@mui/material/styles";
import { FormCategory } from "@simplyk/common";
import classnames from "classnames";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import {
  WhatsappShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

import { AmplitudeEvents } from "../../constants/amplitude";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useTranslate } from "../../hooks/useTranslate";
import { zeffyTheme } from "../../theme/theme";
import { Typography } from "../design-system/Typography";

import { BUTTON_SIZE, getFlyingButtonStyles } from "./styles";
import { ShareThisButton } from "./types";

import { Icon, IconProps } from "@/design-system/Icon";
import {
  X as XIcon,
  Whatsapp as WhatsappIcon,
  Messenger as MessengerIcon,
  Email as MailIcon,
  LinkedIn as LinkedInIcon,
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  ShareLink as ShareIcon,
} from "@/icons/social";

const PREFIX = "ZeffyShareThis";

const zeffyShareThisClasses = {
  shareThis: `${PREFIX}-shareThis`,
  centered: `${PREFIX}-centered`,
  subtitle: `${PREFIX}-subtitle`,
  shareIcons: `${PREFIX}-shareIcons`,
  shareButton: `${PREFIX}-shareButton`,
  flyingButton: `${PREFIX}-flyingButton`,
};

interface RootProps {
  spaceBetweenButton?: number;
  noMargin?: boolean;
  buttonSize?: number;
  circleColor?: string;
  theme: Theme;
}

const Root = React.memo(
  styled("div")(({ theme, spaceBetweenButton, noMargin, buttonSize = BUTTON_SIZE, circleColor }: RootProps) => ({
    [`&&.${zeffyShareThisClasses.shareThis}`]: {
      margin: noMargin ? 0 : theme.spacing(2, 0),
    },

    [`&&.${zeffyShareThisClasses.centered}`]: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    [`&& .${zeffyShareThisClasses.subtitle}`]: {
      color: "white",
      marginBottom: theme.spacing(1.5),
    },

    [`&& .${zeffyShareThisClasses.shareIcons}`]: {
      display: "flex",
      justifyContent: "flex-start",
    },

    [`&& .${zeffyShareThisClasses.shareButton}`]: {
      height: buttonSize,
      width: buttonSize,
      marginRight: theme.spacing(spaceBetweenButton || 1.5),
    },

    [`&& .${zeffyShareThisClasses.flyingButton}`]: getFlyingButtonStyles(theme, buttonSize, circleColor),
  }))
);

type ShareThisProps = React.PropsWithChildren<{
  palette?: "zeffy" | "default";
  description?: string;
  buttons?: ShareThisButton[];
  size?: IconProps["size"];
  title?: string;
  subTitle?: string;
  url?: string | null;
  overrides?: { facebookUrl?: string; messengerUrl?: string };
  classes?: {
    root?: string;
    subtitle?: string;
  };
  centered?: boolean;
  from?: "ReferralPage" | "ThankYouPage";
  spaceBetweenButton?: number;
  noMargin?: boolean;
  formCategory?: FormCategory | null;
  buttonSize?: number;
  iconColor?: string;
  circleColor?: string;
}>;

const DefaultButtons: ShareThisProps["buttons"] = [
  ShareThisButton.Copy,
  ShareThisButton.Facebook,
  ShareThisButton.Messenger,
  ShareThisButton.Linkedin,
  ShareThisButton.X,
  ShareThisButton.Whatsapp,
  ShareThisButton.Email,
];

const InternalShareThis: React.FunctionComponent<Omit<ShareThisProps, "color">> = ({
  buttons = DefaultButtons,
  children,
  classes,
  description,
  size = "small",
  title,
  subTitle,
  url: propUrl,
  overrides,
  centered = false,
  from,
  spaceBetweenButton,
  noMargin,
  buttonSize,
  formCategory,
  iconColor,
  circleColor,
}) => {
  const router = useRouter();
  const url = propUrl || `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${router.locale}${router.asPath}`;
  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const { logAmplitudeEvent, logAmplitudeEventCallback } = useAmplitude();

  const amplitudePayload = React.useMemo(() => {
    if (from !== "ReferralPage") {
      return { form_type: formCategory };
    }
  }, [formCategory, from]);

  const amplitudeEvent = React.useMemo(() => {
    if (from === "ReferralPage") {
      return AmplitudeEvents.ReferralSharingClicked;
    }
    if (from === "ThankYouPage") {
      return AmplitudeEvents.ThankYouPageSharingClicked;
    }
    return AmplitudeEvents.FormSharingClicked;
  }, [from]);

  const handleCopyLink = React.useCallback(() => {
    logAmplitudeEvent(amplitudeEvent, { ...amplitudePayload, social_platform: "Copy Link" });

    navigator.clipboard.writeText(url as string);
    enqueueSnackbar(t("dashboard", "common.linkCopied"), {
      vibe: "positive",
    });
  }, [url, enqueueSnackbar, t, logAmplitudeEvent, amplitudeEvent, amplitudePayload]);

  const copyButton = (
    <div onClick={handleCopyLink} className={zeffyShareThisClasses.shareButton} key="copy">
      <Icon vibe="primary" size={size} className={zeffyShareThisClasses.flyingButton}>
        <ShareIcon />
      </Icon>
    </div>
  );

  const facebookButton = (
    <FacebookShareButton
      url={overrides?.facebookUrl || url}
      className={zeffyShareThisClasses.shareButton}
      key="facebook"
      type="button"
    >
      <Icon
        vibe="primary"
        size={size}
        className={zeffyShareThisClasses.flyingButton}
        onClick={logAmplitudeEventCallback(amplitudeEvent, {
          ...amplitudePayload,
          social_platform: "Facebook",
        })}
      >
        <FacebookIcon />
      </Icon>
    </FacebookShareButton>
  );

  const messengerButton = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID && (
    <FacebookMessengerShareButton
      url={overrides?.messengerUrl || url}
      appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
      className={zeffyShareThisClasses.shareButton}
      key="messenger"
      type="button"
    >
      <Icon
        vibe="primary"
        size={size}
        className={zeffyShareThisClasses.flyingButton}
        onClick={logAmplitudeEventCallback(amplitudeEvent, {
          ...amplitudePayload,
          social_platform: "Messenger",
        })}
      >
        <MessengerIcon />
      </Icon>
    </FacebookMessengerShareButton>
  );

  const xButton = (
    <TwitterShareButton
      url={url}
      title={title}
      className={zeffyShareThisClasses.shareButton}
      key="twitter"
      type="button"
    >
      <Icon
        vibe="primary"
        size={size}
        className={zeffyShareThisClasses.flyingButton}
        onClick={logAmplitudeEventCallback(amplitudeEvent, {
          ...amplitudePayload,
          social_platform: "X",
        })}
      >
        <XIcon />
      </Icon>
    </TwitterShareButton>
  );

  const linkedInButton = (
    <LinkedinShareButton url={url} className={zeffyShareThisClasses.shareButton} key="linkedIn" type="button">
      <Icon
        vibe="primary"
        size={size}
        className={zeffyShareThisClasses.flyingButton}
        onClick={logAmplitudeEventCallback(amplitudeEvent, {
          ...amplitudePayload,
          social_platform: "LinkedIn",
        })}
      >
        <LinkedInIcon />
      </Icon>
    </LinkedinShareButton>
  );

  const emailButton = (
    <EmailShareButton
      url={url}
      title={title}
      body={description}
      className={zeffyShareThisClasses.shareButton}
      key="email"
      type="button"
    >
      <Icon
        vibe="primary"
        size={size}
        className={zeffyShareThisClasses.flyingButton}
        onClick={logAmplitudeEventCallback(amplitudeEvent, {
          ...amplitudePayload,
          social_platform: "Email",
        })}
      >
        <MailIcon />
      </Icon>
    </EmailShareButton>
  );

  const whatsappButton = (
    <WhatsappShareButton
      url={url}
      title={title}
      className={zeffyShareThisClasses.shareButton}
      key="whatsapp"
      type="button"
    >
      <Icon
        vibe="primary"
        size={size}
        className={zeffyShareThisClasses.flyingButton}
        onClick={logAmplitudeEventCallback(amplitudeEvent, {
          ...amplitudePayload,
          social_platform: "WhatsApp",
        })}
      >
        <WhatsappIcon />
      </Icon>
    </WhatsappShareButton>
  );

  const instagramButton = (
    <Icon
      vibe="primary"
      size={size}
      className={zeffyShareThisClasses.flyingButton}
      onClick={logAmplitudeEventCallback(amplitudeEvent, {
        ...amplitudePayload,
        social_platform: "Instagram",
      })}
    >
      <InstagramIcon />
    </Icon>
  );

  return (
    <Root
      className={classnames(zeffyShareThisClasses.shareThis, classes?.root, {
        [zeffyShareThisClasses.centered]: centered,
      })}
      suppressHydrationWarning
      spaceBetweenButton={spaceBetweenButton}
      noMargin={noMargin}
      buttonSize={buttonSize}
      circleColor={circleColor}
    >
      {subTitle && (
        <Typography variant="subtitle1" className={classnames(zeffyShareThisClasses.subtitle, classes?.subtitle)}>
          {subTitle}
        </Typography>
      )}
      <Box
        component="div"
        className={zeffyShareThisClasses.shareIcons}
        sx={{
          svg: iconColor
            ? {
                fill: iconColor,
              }
            : {},
        }}
      >
        {children}
        {buttons.map((button) => {
          if (button === ShareThisButton.Copy) {
            return copyButton;
          }
          if (button === ShareThisButton.Facebook) {
            return facebookButton;
          }
          if (button === ShareThisButton.Messenger) {
            return messengerButton;
          }
          if (button === ShareThisButton.X) {
            return xButton;
          }
          if (button === ShareThisButton.Linkedin) {
            return linkedInButton;
          }
          if (button === ShareThisButton.Email) {
            return emailButton;
          }
          if (button === ShareThisButton.Whatsapp) {
            return whatsappButton;
          }
          if (button === ShareThisButton.Instagram) {
            return instagramButton;
          }
        })}
      </Box>
    </Root>
  );
};

export const ShareThis: React.FunctionComponent<ShareThisProps> = ({ palette = "default", ...rest }) =>
  palette === "zeffy" ? (
    <ThemeProvider theme={zeffyTheme}>
      <InternalShareThis {...rest} />
    </ThemeProvider>
  ) : (
    <InternalShareThis {...rest} />
  );
