import { useEffect, useState } from "react";

import dynamic from "next/dynamic";

const Lottie = dynamic(() => import("react-lottie"), { ssr: false });

export const ZeffyConfetti = ({ canLoadData }: { canLoadData: boolean }) => {
  const [zeffyConfettiData, setZeffyConfettiData] = useState<{ left: unknown; right: unknown } | null>(null);

  useEffect(() => {
    const fetchConfettiData = async () => {
      if (canLoadData) {
        setZeffyConfettiData({
          left: await import("./animations/zeffy_confetti_left.json"),
          right: await import("./animations/zeffy_confetti_right.json"),
        });
      }
    };
    fetchConfettiData();
  }, [canLoadData]);

  return (
    <>
      {zeffyConfettiData && (
        <>
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              pointerEvents: "none",
            }}
          >
            <Lottie
              options={{
                animationData: zeffyConfettiData.left,
                autoplay: true,
                loop: false,
              }}
              isClickToPauseDisabled={true}
            />
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              pointerEvents: "none",
            }}
          >
            <Lottie
              options={{
                animationData: zeffyConfettiData.right,
                autoplay: true,
                loop: false,
              }}
              isClickToPauseDisabled={true}
            />
          </div>
        </>
      )}
    </>
  );
};
