export enum ShareThisButton {
  Copy = "copy",
  Email = "email",
  Facebook = "facebook",
  Linkedin = "linkedin",
  Messenger = "messenger",
  X = "x",
  Whatsapp = "whatsapp",
  Instagram = "instagram",
}
