import { useContext } from "react";

import Grid from "@mui/material/Grid";

import { EmbedChoice, ShareFormModalContext } from "../../../contexts/ShareFormModalContext";
import { useTranslate } from "../../../hooks/useTranslate";
import { DialogCloseButton } from "../../DialogCloseButton/DialogCloseButton";
import { useStyles } from "../useStyles";

import { Typography } from "@/design-system/Typography";

interface ShareFormModalEmbedContentTitleProps {
  isChosen: boolean;
  tab: EmbedChoice;
}

export const ShareFormModalEmbedContentTitle = ({ isChosen, tab }: ShareFormModalEmbedContentTitleProps) => {
  const { t } = useTranslate();
  const classes = useStyles();
  const { onClose } = useContext(ShareFormModalContext);

  return (
    <Grid container>
      <DialogCloseButton data-test="share-form-modal-close-button" onClose={onClose} vibe="text-reverse-intense" />
      <Grid item xs={12} className={classes.dialogTitleContent}>
        <Grid container justifyContent="center">
          <Grid item xs={1} />
          <Grid item xs={10} marginBottom={1}>
            <Typography vibe="text-reverse-intense" variant="h5" align="center">
              {isChosen
                ? tab === EmbedChoice.LinkedButton
                  ? t("dashboard", "shareFormModal.embed.choiceLinkedButton.title")
                  : t("dashboard", "shareFormModal.embed.choice.title")
                : t("dashboard", "shareFormModal.embed.title")}
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
    </Grid>
  );
};
