import { useCallback } from "react";

export const useTextFieldWithCopyIcon = (textInput: HTMLInputElement | null, onCopy?: () => void) => {
  const disableChange = useCallback(() => {
    return;
  }, []);
  const selectFullLink = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    return event && event.target && event.target.select();
  }, []);
  const handleLinkCopy = useCallback(() => {
    textInput?.select();
    document.execCommand("copy");
    onCopy?.();
  }, [onCopy, textInput]);
  return { disableChange, selectFullLink, handleLinkCopy };
};
