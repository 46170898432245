import { FunctionComponent, PropsWithChildren, useCallback, useState, MouseEvent } from "react";

import { Nullable } from "@simplyk/common";
import { useSnackbar } from "notistack";

import { Button, ButtonProps } from "../../components/design-system/Button";
import { AmplitudeEvents } from "../../constants/amplitude";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useTranslate } from "../../hooks/useTranslate";

export interface ButtonCopyProps extends ButtonProps {
  toCopy: Nullable<string> | [ClipboardItem];
  copyText?: string;
  copiedText?: string;
  loading?: boolean;
  amplitudeEvent?: AmplitudeEvents;
  amplitudePayload?: Record<string, unknown>;
}

export const ButtonCopy: FunctionComponent<PropsWithChildren<ButtonCopyProps>> = ({
  toCopy,
  loading,
  onClick,
  copyText: propsCopyText,
  copiedText: propsCopiedText,
  amplitudeEvent,
  amplitudePayload,
  ...rest
}) => {
  const { t } = useTranslate();
  const { logAmplitudeEvent } = useAmplitude();
  const { enqueueSnackbar } = useSnackbar();
  const copyText = propsCopyText || t("common", "copy");
  const copiedText = propsCopiedText || t("dashboard", "buttonCopy.copied");

  const [text, setText] = useState(copyText);

  const copyLink = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      let timeout: Nullable<NodeJS.Timeout> = null;
      if (amplitudeEvent) {
        logAmplitudeEvent(amplitudeEvent, amplitudePayload);
      }
      if (!toCopy) {
        enqueueSnackbar(t("dashboard", "buttonCopy.linkNotSuccessfullyCopy"), { vibe: "danger" });
        return;
      }

      try {
        if (typeof toCopy === "string") {
          navigator.clipboard.writeText(toCopy);
        } else {
          navigator.clipboard.write(toCopy);
        }
        setText(copiedText);
        timeout = setTimeout(() => {
          setText(copyText);
        }, 800);
        onClick?.(event);
      } catch {
        enqueueSnackbar(t("dashboard", "magicalLinkNotCopy"), { vibe: "warning" });
        return;
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    },
    [amplitudeEvent, toCopy, logAmplitudeEvent, amplitudePayload, enqueueSnackbar, t, copiedText, onClick, copyText]
  );

  return (
    <Button {...rest} disabled={text === copiedText || loading} onClick={copyLink}>
      {text}
    </Button>
  );
};
