import { useCallback } from "react";

import Grid, { GridProps } from "@mui/material/Grid";

import { Button } from "../design-system/Button";

interface ButtonTabsProps<T = string> {
  tabs: { value: T; label: string; sticker?: React.ReactNode }[];
  activeTab: T;
  onTabChange: (tab: T) => void;
  justifyContent?: GridProps["justifyContent"];
}

export function ButtonTabs<T = string>({ tabs, activeTab, onTabChange, justifyContent }: ButtonTabsProps<T>) {
  const handleTabChange = useCallback(
    (tab: T) => () => {
      onTabChange(tab);
    },
    [onTabChange]
  );
  return (
    <Grid container justifyContent={justifyContent}>
      {tabs.map((tab, index) => (
        <Grid item key={tab.label} marginRight={index === tabs.length - 1 ? 0 : 1} sx={{ position: "relative" }}>
          <Button
            onClick={handleTabChange(tab.value)}
            variant={tab.value === activeTab ? "tonal" : "text"}
            vibe="brand"
          >
            {tab.label}
          </Button>
          {tab.sticker && (
            <Grid
              sx={{
                position: "absolute",
                top: "5%",
                left: "95%",
                transform: "translateX(-50%) translateY(-50%)",
                width: "50px",
              }}
            >
              {tab.sticker}
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
}
