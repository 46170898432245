import { memo } from "react";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import { useMediaQuery } from "../../hooks/useMediaQuery";

import { Icon } from "@/design-system/Icon";
import { IconProps } from "@/design-system/Icon/Icon";
import { Cross as Close } from "@/icons/outlined";

const PREFIX = "DialogCloseButton";

const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const StyledGrid = memo(
  styled(Grid)(() => ({
    [`&& .${classes.closeButton}`]: {
      cursor: "pointer",
      zIndex: 10,
    },
  }))
);

interface DialogCloseButtonProps {
  "data-test"?: string;
  onClose: () => void;
  vibe: IconProps["vibe"];
}

export const DialogCloseButton = ({ "data-test": dataTest, onClose, vibe }: DialogCloseButtonProps) => {
  const { isSmallScreen } = useMediaQuery();
  return (
    <StyledGrid
      item
      container
      justifyContent="flex-end"
      marginRight={isSmallScreen ? 2 : 0}
      marginTop={isSmallScreen ? 2 : 0}
      xs={12}
    >
      <Icon onClick={onClose} className={classes.closeButton} vibe={vibe} data-test={dataTest}>
        <Close />
      </Icon>
    </StyledGrid>
  );
};
