export const lexusTextArt = `                                                                                     
LLLLLLLLLLL                               XXXXXXX       XXXXXXX                     SSSSSSSSSSSSSSS 
L:::::::::L                               X:::::X       X:::::X                   SS:::::::::::::::S
L:::::::::L                               X:::::X       X:::::X                  S:::::SSSSSS::::::S
LL:::::::LL                               X::::::X     X::::::X                  S:::::S     SSSSSSS
  L:::::L                   eeeeeeeeeeee  XXX:::::X   X:::::XXXuuuuuu    uuuuuu  S:::::S            
  L:::::L                 ee::::::::::::ee   X:::::X X:::::X   u::::u    u::::u  S:::::S            
  L:::::L                e::::::eeeee:::::ee  X:::::X:::::X    u::::u    u::::u   S::::SSSS         
  L:::::L               e::::::e     e:::::e   X:::::::::X     u::::u    u::::u    SS::::::SSSSS    
  L:::::L               e:::::::eeeee::::::e   X:::::::::X     u::::u    u::::u      SSS::::::::SS  
  L:::::L               e:::::::::::::::::e   X:::::X:::::X    u::::u    u::::u         SSSSSS::::S 
  L:::::L               e::::::eeeeeeeeeee   X:::::X X:::::X   u::::u    u::::u              S:::::S
  L:::::L         LLLLLLe:::::::e         XXX:::::X   X:::::XXXu:::::uuuu:::::u              S:::::S
LL:::::::LLLLLLLLL:::::Le::::::::e        X::::::X     X::::::Xu:::::::::::::::uuSSSSSSS     S:::::S
L::::::::::::::::::::::L e::::::::eeeeeeeeX:::::X       X:::::X u:::::::::::::::uS::::::SSSSSS:::::S
L::::::::::::::::::::::L  ee:::::::::::::eX:::::X       X:::::X  uu::::::::uu:::uS:::::::::::::::SS 
LLLLLLLLLLLLLLLLLLLLLLLL    eeeeeeeeeeeeeeXXXXXXX       XXXXXXX    uuuuuuuu  uuuu SSSSSSSSSSSSSSS   
`;
