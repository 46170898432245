/* eslint-disable sonarjs/no-duplicate-string */
import { useContext, useMemo } from "react";

import Grid from "@mui/material/Grid";
import { Locales } from "@simplyk/common";

import EasyEn from "../../../../public/images/sticker/easy_en.svg";
import EasyFr from "../../../../public/images/sticker/easy_fr.svg";
import { AmplitudeEvents } from "../../../constants/amplitude";
import { EMBEDDED_FORM_SCRIPT } from "../../../constants/EmbedForm";
import { useLocaleContext } from "../../../contexts/LocaleContext";
import { EmbedChoice, ShareFormModalContext } from "../../../contexts/ShareFormModalContext";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useTranslate } from "../../../hooks/useTranslate";
import { ButtonTabs } from "../../ButtonTabs/ButtonTabs";
import { Button } from "../../design-system/Button";
import { Divider } from "../../design-system/Divider/Divider";
import { Infobox } from "../../design-system/Infobox";
import { Typography } from "../../design-system/Typography";
import Image from "../../Image/Image";
import { TextFieldWithCopy } from "../../TextFieldWithCopy/TextFieldWithCopy";
import { useStyles } from "../useStyles";

import { ExternalLink as Launch } from "@/icons/outlined";

const gifSrcByTab = {
  [EmbedChoice.Form]: "/gifs/form-embed.gif",
  [EmbedChoice.ButtonPopup]: "/gifs/popup-embed.gif",
  [EmbedChoice.Thermometer]: "/gifs/thermometer.gif",
  [EmbedChoice.Leaderboard]: "/gifs/leaderboard.gif",
  [EmbedChoice.LinkedButton]: "/gifs/linked-button.gif",
};

const getSticker = {
  [EmbedChoice.LinkedButton]: { [Locales.EN]: <EasyEn />, [Locales.FR]: <EasyFr />, [Locales.ES]: <EasyEn /> },
  [EmbedChoice.Form]: undefined,
  [EmbedChoice.ButtonPopup]: undefined,
  [EmbedChoice.Thermometer]: undefined,
  [EmbedChoice.Leaderboard]: undefined,
};

const guideLinks = {
  [EmbedChoice.Form]: {
    [Locales.EN]: "https://support.zeffy.com/how-do-i-share-my-form#embed",
    [Locales.FR]: "https://support.zeffy.com/fr-ca/comment-puis-je-partager-mon-formulaire#integrer",
    // TODO: Update the link when the Spanish guide is available
    [Locales.ES]: "https://support.zeffy.com/how-do-i-share-my-form#embed",
  },
  [EmbedChoice.ButtonPopup]: {
    [Locales.EN]: "https://support.zeffy.com/how-do-i-share-my-form#pop-up",
    [Locales.FR]: "https://support.zeffy.com/fr-ca/comment-puis-je-partager-mon-formulaire#pop-up",
    // TODO: Update the link when the Spanish guide is available
    [Locales.ES]: "https://support.zeffy.com/how-do-i-share-my-form#pop-up",
  },
  [EmbedChoice.Thermometer]: {
    [Locales.EN]: "https://support.zeffy.com/how-do-i-share-my-form#thermometer",
    [Locales.FR]: "https://support.zeffy.com/fr-ca/comment-puis-je-partager-mon-formulaire#thermometre",
    // TODO: Update the link when the Spanish guide is available
    [Locales.ES]: "https://support.zeffy.com/how-do-i-share-my-form#thermometer",
  },
  [EmbedChoice.Leaderboard]: {
    [Locales.EN]: "https://support.zeffy.com/how-do-i-share-my-form#leaderboard",
    [Locales.FR]: "https://support.zeffy.com/fr-ca/comment-puis-je-partager-mon-formulaire#leaderboard",
    // TODO: Update the link when the Spanish guide is available
    [Locales.ES]: "https://support.zeffy.com/how-do-i-share-my-form#leaderboard",
  },
  [EmbedChoice.LinkedButton]: {
    [Locales.EN]: "https://support.zeffy.com/add-a-donate-button-to-a-website",
    [Locales.FR]: "https://support.zeffy.com/fr-ca/ajouter-un-bouton-%C3%A0-un-site-web-pour-partager-votre-formulaire",
    // TODO: Update the link when the Spanish guide is available
    [Locales.ES]: "https://support.zeffy.com/add-a-donate-button-to-a-website",
  },
};

interface ShareFormModalEmbedContentContentProps {
  isChosen: boolean;
  tab: EmbedChoice;
  handleTabChange: (value: EmbedChoice) => void;
}

export const ShareFormModalEmbedContentContent = ({
  tab,
  handleTabChange,
  isChosen,
}: ShareFormModalEmbedContentContentProps) => {
  const { t } = useTranslate();
  const { locale } = useLocaleContext();
  const { isSmallScreen } = useMediaQuery();
  const {
    embedChoices,
    formIframeHtml,
    leaderboardIframeHtml,
    thermometerIframeHtml,
    buttonZeffyProperty,
    formCategory,
    formLink,
    demotivateEmbeds,
  } = useContext(ShareFormModalContext);
  const htmlContent = useMemo(() => {
    switch (tab) {
      case EmbedChoice.Form:
        return formIframeHtml;
      case EmbedChoice.Thermometer:
        return thermometerIframeHtml;
      case EmbedChoice.Leaderboard:
        return leaderboardIframeHtml;
      case EmbedChoice.LinkedButton:
        return formLink || "";
      default:
        return "";
    }
  }, [formIframeHtml, formLink, leaderboardIframeHtml, tab, thermometerIframeHtml]);
  const classes = useStyles();
  if (isChosen) {
    switch (tab) {
      case EmbedChoice.Form:
      case EmbedChoice.Thermometer:
      case EmbedChoice.Leaderboard:
        return (
          <Grid container justifyContent="center">
            <Grid item xs={12} marginBottom={3} className={classes.textAlignCenter}>
              <Image
                src={
                  locale === Locales.EN
                    ? "/images/shareFormModal/form_embed_en.svg"
                    : "/images/shareFormModal/form_embed_fr.svg"
                }
                alt="Form embed"
                height={isSmallScreen ? 75 : 100}
                width={undefined}
              />
            </Grid>
            <Grid item xs={12} marginBottom={3}>
              <Divider />
            </Grid>
            <Grid item xs={12} marginBottom={0.5}>
              <Typography variant="subtitle2">{t("dashboard", "shareFormModal.embed.choice.label")}</Typography>
            </Grid>
            <Grid item xs={12} marginBottom={3}>
              <TextFieldWithCopy
                button
                value={htmlContent}
                size="medium"
                buttonCopyProps={{ vibe: "brand", variant: "filled", size: "medium" }}
                copyAmplitudeEvent={AmplitudeEvents.ShareFormModalClickCopyEmbed}
                copyAmplitudePayload={{ form_embed_type: tab, form_type: formCategory }}
              />
            </Grid>
            <Grid item xs={12} marginBottom={1} className={classes.textAlignCenter}>
              <Typography variant="subtitle2">{t("dashboard", "shareFormModal.embed.choice.extraHelp")}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.textAlignCenter}>
              <Button
                vibe="brand"
                variant="outlined"
                size="medium"
                endIcon={<Launch />}
                href={guideLinks[tab][locale]}
                target="_blank"
              >
                {t("dashboard", "shareFormModal.embed.choice.visitOurGuide")}
              </Button>
            </Grid>
          </Grid>
        );
      case EmbedChoice.ButtonPopup:
        return (
          <Grid container justifyContent="center">
            <Grid item xs={12} marginBottom={3} className={classes.textAlignCenter}>
              <Image
                src={
                  locale === Locales.EN
                    ? "/images/shareFormModal/button_popup_en.svg"
                    : "/images/shareFormModal/button_popup_fr.svg"
                }
                alt="Form embed"
                height={isSmallScreen ? 60 : 100}
                width={undefined}
              />
            </Grid>
            <Grid item xs={12} marginBottom={3}>
              <Divider />
            </Grid>
            <Grid item xs={12} marginBottom={0.5}>
              <Typography variant="subtitle2">
                {t("dashboard", "shareFormModal.embed.choice.ButtonPopup.label1")}
              </Typography>
            </Grid>
            <Grid item xs={12} marginBottom={2}>
              <TextFieldWithCopy
                button
                value={buttonZeffyProperty}
                size="medium"
                buttonCopyProps={{ vibe: "brand", variant: "filled", size: "medium" }}
                copyAmplitudeEvent={AmplitudeEvents.ShareFormModalClickCopyEmbed}
                copyAmplitudePayload={{ form_embed_type: tab, property: "button-property", form_type: formCategory }}
              />
            </Grid>
            <Grid item xs={12} marginBottom={0.5}>
              <Typography variant="subtitle2">
                {t("dashboard", "shareFormModal.embed.choice.ButtonPopup.label2")}
              </Typography>
            </Grid>
            <Grid item xs={12} marginBottom={3}>
              <TextFieldWithCopy
                button
                value={EMBEDDED_FORM_SCRIPT}
                size="medium"
                buttonCopyProps={{ vibe: "brand", variant: "filled", size: "medium" }}
                copyAmplitudeEvent={AmplitudeEvents.ShareFormModalClickCopyEmbed}
                copyAmplitudePayload={{ form_embed_type: tab, property: "button-script", form_type: formCategory }}
              />
            </Grid>
            <Grid item xs={12} marginBottom={1} className={classes.textAlignCenter}>
              <Typography variant="subtitle2">{t("dashboard", "shareFormModal.embed.choice.extraHelp")}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.textAlignCenter}>
              <Button
                vibe="brand"
                variant="outlined"
                size="medium"
                endIcon={<Launch />}
                href={guideLinks[tab][locale]}
                target="_blank"
              >
                {t("dashboard", "shareFormModal.embed.choice.visitOurGuide")}
              </Button>
            </Grid>
          </Grid>
        );
      case EmbedChoice.LinkedButton:
        return (
          <Grid container justifyContent="center">
            <Grid item xs={12} marginBottom={3} className={classes.textAlignCenter}>
              <Image
                src={
                  locale === Locales.EN
                    ? "/images/shareFormModal/linked_button_en.svg"
                    : "/images/shareFormModal/linked_button_fr.svg"
                }
                alt="Form embed"
                height={isSmallScreen ? 75 : 100}
                width={undefined}
              />
            </Grid>
            <Grid item xs={12} marginBottom={3}>
              <Divider />
            </Grid>
            <Grid item xs={12} marginBottom={0.5}>
              <Typography variant="subtitle2">
                {t("dashboard", "shareFormModal.embed.choiceLinkedButton.label")}
              </Typography>
            </Grid>
            <Grid item xs={12} marginBottom={3}>
              <TextFieldWithCopy
                button
                value={htmlContent}
                size="medium"
                buttonCopyProps={{ vibe: "brand", variant: "filled", size: "medium" }}
                copyAmplitudeEvent={AmplitudeEvents.ShareFormModalClickCopyLinkedButton}
                copyAmplitudePayload={{ form_embed_type: tab, form_type: formCategory }}
              />
            </Grid>
            <Grid item xs={12} marginBottom={1} className={classes.textAlignCenter}>
              <Typography variant="subtitle2">{t("dashboard", "shareFormModal.embed.choice.extraHelp")}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.textAlignCenter}>
              <Button
                vibe="brand"
                variant="outlined"
                size="medium"
                endIcon={<Launch />}
                href={guideLinks[tab][locale]}
                target="_blank"
              >
                {t("dashboard", "shareFormModal.embed.choice.visitOurGuide")}
              </Button>
            </Grid>
          </Grid>
        );
      default:
        break;
    }
  }
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} marginBottom={3}>
        <ButtonTabs
          justifyContent="center"
          activeTab={tab}
          onTabChange={handleTabChange}
          tabs={embedChoices.map((embedChoice) => ({
            value: EmbedChoice[embedChoice],
            label: t("dashboard", `shareFormModal.embed.tab.${embedChoice}`),
            sticker: tab === EmbedChoice.LinkedButton ? getSticker?.[embedChoice]?.[locale] : undefined,
          }))}
        />
      </Grid>
      {[EmbedChoice.ButtonPopup, EmbedChoice.Form].includes(tab) && demotivateEmbeds && (
        <Grid item xs={12} marginRight={2} marginLeft={2} marginBottom={2}>
          <Infobox vibe="warning" type="alert" size="small">
            {t(
              "dashboard",
              `shareFormModal.embed.tab.${tab as EmbedChoice.ButtonPopup | EmbedChoice.Form}.warning.content`
            )}
          </Infobox>
        </Grid>
      )}
      {!isSmallScreen && (
        <Grid item xs={12} marginRight={2} marginLeft={2}>
          <Infobox
            vibe="brand"
            type="alert"
            size="medium"
            pointer="bottom"
            hideIcon
            title={t("dashboard", `shareFormModal.embed.tab.${tab}.infoBox.title`)}
          >
            {t("dashboard", `shareFormModal.embed.tab.${tab}.infoBox.content`)}
          </Infobox>
        </Grid>
      )}
      <Grid item xs={12} className={classes.textAlignCenter}>
        <Image src={gifSrcByTab[tab]} alt="Form embed" width={isSmallScreen ? 260 : 450} height={undefined} />
      </Grid>
      {isSmallScreen && (
        <Grid item xs={12} marginRight={2} marginLeft={2}>
          <Infobox
            vibe="brand"
            type="alert"
            size="medium"
            pointer="top"
            hideIcon
            title={t("dashboard", `shareFormModal.embed.tab.${tab}.infoBox.title`)}
          >
            {t("dashboard", `shareFormModal.embed.tab.${tab}.infoBox.content`)}
          </Infobox>
        </Grid>
      )}
    </Grid>
  );
};
