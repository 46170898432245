import * as React from "react";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";

import { Button, ButtonProps } from "@/design-system/Button";
import { ExternalLink as Launch } from "@/icons/outlined";

interface OpenFormProps {
  link?: string | null;
  variant?: "outlined" | "filled";
  size?: ButtonProps["size"];
  vibe?: ButtonProps["vibe"];
  onClick?: () => void;
  overrideLabel?: string;
  target?: string | null;
}

export const OpenForm: React.FunctionComponent<React.PropsWithChildren<OpenFormProps>> = ({
  link,
  variant,
  vibe,
  size,
  onClick,
  overrideLabel,
  target = "_blank",
}) => {
  const { t } = useTranslate();
  const { isSmallScreen } = useMediaQuery();
  const label = overrideLabel || t("common", isSmallScreen ? "seeMyFormMobile" : "seeMyForm");

  return link ? (
    <Button
      variant={variant || "outlined"}
      href={link}
      target={target ?? undefined}
      vibe={vibe || "neutral"}
      endIcon={target === "_blank" ? <Launch /> : undefined}
      onClick={onClick}
      fullWidth
      data-test="open-form"
      size={size}
    >
      {label}
    </Button>
  ) : null;
};
