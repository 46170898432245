import {
  FunctionComponent,
  PropsWithChildren,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";

import Grid from "@mui/material/Grid";
import * as QrCode from "qrcode";
import { isFirefox } from "react-device-detect";

import { AmplitudeEvents } from "../../constants/amplitude";
import { ShareFormModalContext } from "../../contexts/ShareFormModalContext";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useTranslate } from "../../hooks/useTranslate";
import { ButtonCopy } from "../ButtonCopy/ButtonCopy";

import { useStyles } from "./useStyles";

import { Button } from "@/design-system/Button";

export interface FormQrCodeProps {
  formLink: string | null;
}

export const FormQrCode: FunctionComponent<PropsWithChildren<FormQrCodeProps>> = ({ formLink }) => {
  const classes = useStyles();
  const QRCodeCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [image, setImage] = useState<{ url: string; blob: Blob } | null>(null);
  const { logAmplitudeEvent } = useAmplitude();
  const { formCategory } = useContext(ShareFormModalContext);

  useEffect(() => {
    if (QRCodeCanvasRef && QRCodeCanvasRef.current && formLink) {
      QrCode.toCanvas(QRCodeCanvasRef.current, formLink);
      QRCodeCanvasRef.current.toBlob((blob) => {
        blob && setImage({ url: URL.createObjectURL(blob), blob });
      });
    }
  }, [QRCodeCanvasRef, formLink]);

  const clipboardItem = useMemo(() => {
    if (image?.blob && !isFirefox) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return new ClipboardItem({ "image/png": image?.blob });
    }
  }, [image?.blob]);
  const handleDownload = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.ShareFormModalClickSharingQrCode, {
      form_type: formCategory,
      qr_code_sharing_type: "Download image",
    });
    if (image?.url) {
      const a = document.createElement("a");
      a.href = image.url;
      a.download = "qr-code.png";
      a.click();
    }
  }, [formCategory, image?.url, logAmplitudeEvent]);
  const { t } = useTranslate();
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} className={classes.qrCode}>
        <canvas ref={QRCodeCanvasRef} />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          {!isFirefox && clipboardItem && (
            <Grid item marginRight={1}>
              <ButtonCopy
                loading={false}
                vibe="brand"
                size="large"
                variant="outlined"
                toCopy={[clipboardItem]}
                copyText={t("dashboard", "common.copyImage")}
                amplitudeEvent={AmplitudeEvents.ShareFormModalClickSharingQrCode}
                amplitudePayload={{ form_type: formCategory, qr_code_sharing_type: "Copy image" }}
              />
            </Grid>
          )}
          <Grid item>
            <Button size="large" vibe="brand" variant="filled" onClick={handleDownload}>
              {t("dashboard", "common.downloadImage")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
