import { AmplitudeFlagKey } from "@simplyk/common";

import { useCurrentUserContext } from "../../contexts/CurrentUserContext";
import { useAmplitudeFeatureFlag } from "../../hooks/amplitude/useAmplitudeFeatureFlag";

export const useCampaignFeatureFlag = () => {
  const { organization } = useCurrentUserContext();
  const { isActive: isCampaignsHubFeatureActivated, loading } = useAmplitudeFeatureFlag({
    flagKey: AmplitudeFlagKey.CampaignHub,
    userProperties: {},
    organizationId: organization?.id,
    organizationProperties: { closing_date: Boolean(organization?.fourthPaymentDate) },
  });

  return { isCampaignsHubFeatureActivated, loading };
};
