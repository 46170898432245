import { FormCategory, FormType, Locales, PaymentMethod } from "@simplyk/common";

import { PaymentStatus, SubscriptionRecurrenceInterval } from "../../gql/gql-types";

export type CampaignPaymentsStatus = "incomplete" | "failed" | "succeeded" | "processing" | "bidPlaced";

export enum CampaignPaymentTab {
  Overview = "overview",
  Payment = "payment",
  Guest = "guest",
  Communication = "communication",
  Product = "product",
}

export interface CampaignPaymentsFilters {
  formIds?: string[];
  occurrenceIds?: string[] | null;
  search?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  statuses?: CampaignPaymentsStatus[] | null;
}

export interface CampaignPaymentsObject {
  id: string;
  commandId: string;
  formId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactId: string;
  userId: string;
  corporationName: string;
  createdAtUtc: string;
  paymentMethod: PaymentMethod;
  extraDonation: number;
  formType: FormType;
  transactionId: string;
  transactionStatus: PaymentStatus;
  status: "incomplete" | "failed" | "succeeded" | "processing" | "bidPlaced" | "onHold" | "unprocessed";
  transferrableAmount: number;
  description: string;
  refundedAmount: number;
  isDisputeLost: boolean;
  hasRadarWarning: boolean;
}

export interface CampaignPaymentsTransactionDetails {
  id: string;
  subscriptionId?: string | null;
  recurrenceInterval?: SubscriptionRecurrenceInterval | null;
  eventStartAt?: string | null;
  isExpiring?: boolean | null;
  expirationDate?: string | null;
  stoppedAt?: string | null;
  nextRenewalAt?: string | null;
  subscriptionStatus?: "Created" | "Active" | "PastDue" | "Canceled" | null;
  allTicketsCancelled?: boolean | null;
  isNewRecurringDonation?: boolean | null;
}

export interface CampaignPaymentsSort {
  sort: "transactions_created_at_utc" | "command_full_name" | "amount";
  order: "ASC" | "DESC";
}

export interface CampaignPaymentForm {
  id: string;
  formType: FormType;
  status: "draft" | "active";
  hasMultidate: boolean;
  formCategory: FormCategory;
  closed?: boolean;
  fields?:
    | {
        locale: Locales;
        id: string;
        description: string;
        title: string;
      }[]
    | undefined;
}
