import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    linkInputLabel: {
      textAlign: "left",
      color: theme.palette.text.reverse.intense,
      marginBottom: theme.spacing(0.5),
    },
    textAlignCenter: {
      textAlign: "center",
    },
    copyInfoBox: {
      textAlign: "left",
    },
  }),
  { index: 10 }
);
