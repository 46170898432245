import { Ref, useMemo, useEffect, useState, useCallback } from "react";

import Grid from "@mui/material/Grid";
import classNames from "classnames";

import { AmplitudeEvents } from "../../constants/amplitude";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useTranslate } from "../../hooks/useTranslate";
import { ButtonCopy, ButtonCopyProps } from "../ButtonCopy/ButtonCopy";

import { useStyles } from "./useStyles";
import { useTextFieldWithCopyIcon } from "./useTextFieldWithCopyIcon";

import { Link } from "@/design-system/Link";
import { TextField, BaseTextFieldProps } from "@/design-system/TextField";

interface TextFieldInputProps extends BaseTextFieldProps {
  "data-test"?: string;
  value: string | null;
  onCopy?: () => void;
  additionalButtonGroup?: React.ReactNode;
  multiline?: boolean;
  button?: boolean;
  buttonCopyProps?: Omit<ButtonCopyProps, "toCopy">;
  copyAmplitudeEvent?: AmplitudeEvents;
  copyAmplitudePayload?: Record<string, unknown>;
  copyButtonBelow?: boolean;
}

export function TextFieldWithCopy({
  value,
  onCopy,
  ref,
  size,
  button = false,
  buttonCopyProps,
  copyAmplitudeEvent,
  copyAmplitudePayload,
  copyButtonBelow = false,
  ...props
}: TextFieldInputProps) {
  const [textInput, setTextInput] = useState<HTMLInputElement | null>(null);
  const { disableChange, selectFullLink, handleLinkCopy } = useTextFieldWithCopyIcon(textInput, onCopy);
  const classes = useStyles();
  const { t } = useTranslate();
  const { logAmplitudeEvent } = useAmplitude();

  useEffect(() => {
    const handleCopyEvent = () => {
      if (copyAmplitudeEvent) {
        logAmplitudeEvent(copyAmplitudeEvent, copyAmplitudePayload);
      }
      onCopy?.();
    };

    if (textInput) {
      textInput.addEventListener("copy", handleCopyEvent);
    }

    return () => {
      if (textInput) {
        textInput.removeEventListener("copy", handleCopyEvent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textInput]);

  const InputProps = useMemo(
    () => ({
      className: classNames(classes.input, { [classes.multiline]: props.multiline }),
      ...props.InputProps,
    }),
    [classes.input, classes.multiline, props.multiline, props.InputProps]
  );

  const handleSetInputRef = useCallback((currentInputRef: HTMLInputElement) => setTextInput(currentInputRef), []);

  return (
    <Grid container alignItems={copyButtonBelow ? "flex-start" : "center"} spacing={copyButtonBelow ? 1 : 0}>
      <Grid item xs>
        <TextField
          {...props}
          ref={ref as Ref<HTMLInputElement>}
          inputRef={handleSetInputRef}
          data-test={props["data-test"] || "text-field-with-copy-icon"}
          endAdornment={
            !button && !copyButtonBelow ? (
              <Link vibe="brand" onClick={handleLinkCopy} className={classes.leftSpacing}>
                {t("common", "copy")}
              </Link>
            ) : undefined
          }
          onChange={disableChange}
          onFocus={selectFullLink}
          value={value}
          size={size || "small"}
          InputProps={InputProps}
        />
      </Grid>

      {button && !copyButtonBelow ? (
        <Grid item marginLeft={1}>
          <ButtonCopy
            variant={buttonCopyProps?.variant || "filled"}
            vibe={buttonCopyProps?.vibe || "alternate"}
            size={buttonCopyProps?.size || "small"}
            toCopy={value}
            onClick={onCopy}
            amplitudeEvent={copyAmplitudeEvent}
            amplitudePayload={copyAmplitudePayload}
          >
            {t("common", "copy")}
          </ButtonCopy>
        </Grid>
      ) : undefined}

      {button && copyButtonBelow ? (
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <ButtonCopy
            variant={buttonCopyProps?.variant || "filled"}
            vibe={buttonCopyProps?.vibe || "alternate"}
            size={buttonCopyProps?.size || "small"}
            toCopy={value}
            onClick={onCopy}
            amplitudeEvent={copyAmplitudeEvent}
            amplitudePayload={copyAmplitudePayload}
          >
            {t("common", "copy")}
          </ButtonCopy>
        </Grid>
      ) : null}
    </Grid>
  );
}
