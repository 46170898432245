import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    closeButton: {
      cursor: "pointer",
      zIndex: 10,
    },
    dialogTitleContent: {
      paddingBottom: theme.spacing(2),
      textAlign: "center",
    },
    textAlignCenter: {
      textAlign: "center",
    },
  }),
  { index: 10 }
);
