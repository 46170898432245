import { useCallback, useContext } from "react";

import Stack from "@mui/material/Stack";

import { ShareFormModalContext } from "../../../contexts/ShareFormModalContext";
import { useTranslate } from "../../../hooks/useTranslate";
import { SelectBar } from "../../design-system/SelectBar/SelectBar";

interface ShareFormModalContentContentProps {
  onShowQrCodeContent: () => void;
  onShowEmbedContent: () => void;
}

export const ShareFormModalContentContent = ({
  onShowQrCodeContent,
  onShowEmbedContent,
}: ShareFormModalContentContentProps) => {
  const { updateLexusEasterEggCounter } = useContext(ShareFormModalContext);
  const { t } = useTranslate();
  const handleShowEmbedContent = useCallback(() => {
    updateLexusEasterEggCounter(1);
    onShowEmbedContent();
  }, [onShowEmbedContent, updateLexusEasterEggCounter]);
  return (
    <Stack spacing={2}>
      <SelectBar
        title={t("dashboard", "shareFormModal.choice.qrCode.title")}
        description={t("dashboard", "shareFormModal.choice.qrCode.description")}
        onClick={onShowQrCodeContent}
      />

      <SelectBar
        title={t("dashboard", "shareFormModal.choice.embed.title")}
        description={t("dashboard", "shareFormModal.choice.embed.description")}
        onClick={handleShowEmbedContent}
      />
    </Stack>
  );
};
