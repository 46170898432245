import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    input: {
      backgroundColor: "white",
    },
    leftSpacing: {
      marginLeft: theme.spacing(1),
    },
    multiline: {
      alignItems: "flex-end",
    },
  }),
  { index: 10 }
);
