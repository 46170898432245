import Grid from "@mui/material/Grid";
import { useRouter } from "next/router";

import { Chip } from "@/design-system/Chip/Chip";
import { SelectBar } from "@/design-system/SelectBar/SelectBar";
import { Typography } from "@/design-system/Typography/Typography";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useTranslate } from "@/hooks/useTranslate";

interface InvitationEmailModalProps {
  onInvitationEmailClick: () => void;
  onOtherOptionsClick: () => void;
}

export const InvitationEmailModalContentContent = ({
  onInvitationEmailClick,
  onOtherOptionsClick,
}: InvitationEmailModalProps) => {
  const { t } = useTranslate();
  const router = useRouter();
  const givingTuesdayTemplate = router.query.givingTuesdayTemplate === "true";

  const { isSmallScreen } = useMediaQuery();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
          [theme.breakpoints.down("md")]: { marginBottom: theme.spacing(1) },
        })}
      >
        <SelectBar
          title={
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="subtitle1">
                  {givingTuesdayTemplate
                    ? t("dashboard", "shareFormModal.choice.givingTuesday.invitationEmail.title")
                    : t("dashboard", "shareFormModal.choice.invitationEmail.title")}
                </Typography>
              </Grid>
              <Grid item>
                <Chip
                  variant="outlined"
                  vibe="positive"
                  label={t("dashboard", "shareFormModal.choice.invitationEmail.title.chip")}
                />
              </Grid>
            </Grid>
          }
          description={
            isSmallScreen
              ? ""
              : givingTuesdayTemplate
                ? t("dashboard", "shareFormModal.choice.givingTuesday.invitationEmail.description")
                : t("dashboard", "shareFormModal.choice.invitationEmail.description")
          }
          onClick={onInvitationEmailClick}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectBar
          title={t("dashboard", "shareFormModal.choice.otherOptions.title")}
          description={isSmallScreen ? "" : t("dashboard", "shareFormModal.choice.otherOptions.description")}
          onClick={onOtherOptionsClick}
        />
      </Grid>
    </Grid>
  );
};
